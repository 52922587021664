/* Common css for navigation bar */
.navBarWrapper {
  display: flex;
}
.contentWrapper {
  flex: 6;
}
/* Common css for navigation bar */
.meetingRoomWrapper {
  padding: 70px 85px 20px;
  overflow-y: auto;
  height: 87vh;
}
.topMeetingRoomSection {
  display: flex;
  gap: 15px;
  align-items: center;
}

button.addNewButton {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  background-color: #66a3a6;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  border-radius: 5px;
  border: none;
  align-items: center;
}
button.addNewButton a {
  color: #fff;
  text-decoration: none;
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 0px;
}
.topMeetingRoomSection h3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  color: #d9d9d9;
}
.meetingCollectionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 55px;
  height: 80%;
  padding: 30px;
  max-height: 80%;
  overflow: auto;
}
.meetingDiv {
  width: 450px;
  height: 384px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.extraClass {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #fff;
  flex: 0 28%;
  transition: 0.7s;
}

.extraClass:hover {
  background: #66a3a6;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}
.extraClass:hover::before {
  background: rgb(85 108 214 / 10%);
}
.extraClass:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 145px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: 50px;
  border-radius: 35px;
}

.hovercolorbubble {
  display: block;
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: 0rem;
  transition: 0.7s;
}
.extraClass:hover .hovercolorbubble {
  top: -35rem;
  transition: 1.7s;
}
.extraClass:hover .buttonSection button:first-child:hover {
  color: #66a3a6;
  background-color: #fff;
}
.meetingRoomImgClass {
  padding: 10px 5px 0;
  width: 98%;
  height: 50%;
  /* object-fit: contain; */
  background: white;
}
.meetingDetailWrapper {
  padding: 0px 15px 25px;
}
.meetingDetailWrapper h2 {
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 0;
  height: 2.5rem;
  overflow: auto;
}
.meetingDetailWrapper p {
  margin-top: 10px;
  white-space: break-spaces;
  overflow-x: hidden;
  overflow-y: auto;
  height: 2.5rem;
}
.buttonSection {
  display: flex;
}
.buttonSection button:first-child {
  width: 130px;
  height: 36px;
  background-color: #66a3a6;
  font-family: Inter;
  font-size: 19px;
  font-weight: 400;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}
.buttonSection button:nth-child(2) {
  width: 130px;
  height: 36px;
  background-color: #fff;
  font-family: Inter;
  font-size: 19px;
  font-weight: 400;
  color: #ff0000;
  border-radius: 5px;
  border: 1px solid #ff0000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.buttonSection button a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.meetingDiv:hover .buttonSection button:first-child {
  background-color: #fff;
  color: #66a3a6;
}
.meetingDiv:hover .buttonSection button:first-child svg path {
  fill: #66a3a6;
}
.margExtra {
  margin-left: 75px;
}

/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .meetingDiv {
    flex: 0 1 42%;
  }
}
@media only screen and (max-width: 1025px) {
  /* .meetingDiv{
    flex: 0 1 35%;
  } */
}

@media only screen and (max-width: 450px) {
  .contentWrapper {
    width: 100vw;
    overflow-y: auto;
  }

  .meetingRoomWrapper {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 90px;
  }

  .meetingCollectionWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .meetingDiv {
    width: 100%;
    height: 290px;
    background-color: white;
  }
  .meetingRoomImgClass {
    padding: 5px;
  }

  .meetingDetailWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
  }

  .meetingDetailWrapper h2 {
    font-size: 15px;
    font-weight: 400px;
    margin-top: 5px;
  }

  .meetingDetailWrapper p {
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 300px;
    max-width: 100%;
    height: auto;
    max-height: 50px;
  }

  button.addNewButton {
    width: 111px;
    height: 31px;
  }

  button.addNewButton a {
    font-size: 15px;
  }

  .topMeetingRoomSection h3 {
    font-size: 15px;
  }

  .buttonSection {
    padding-top: 5px;
  }

  .buttonSection button:nth-child(1),
  .buttonSection button:nth-child(2) {
    width: 95px;
    height: 30px;
    font-size: 14px;
  }

  .buttonSection button:nth-child(1) svg {
    width: 16px;
    height: 16px;
    padding-right: 5px;
  }

  .buttonSection button img {
    width: 16px;
    height: 16px;
  }
  /* css for animation */
  .meetingDiv {
    flex: auto;
    /* z-index: -1; */
  }
  .meetingDiv:hover .buttonSection button:first-child {
    background-color: #66a3a6;
    color: #fff;
  }
  .hovercolorbubble {
    display: none;
  }
  .meetingDiv:hover .buttonSection button:first-child svg path {
    fill: #fff;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .extraClass {
    flex: unset;
    width: 25%;
    padding: 1rem 1rem;
  }
  .meetingDetailWrapper {
    padding: unset;
  }
}
