.createPolicyForm {
  height: 78vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.svg-container  {
  position: absolute;
  top: 10%;
  left: 3%;
}

.createPolicyForm > form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}

.policyFields {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.policyField {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.policyField label {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 20px;
  opacity: 70%;
}

.policyField input {
  width: 800px;
  height: 50px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 15px;
  font-family: "Lexend";
}

.uploadPolicy {
  position: absolute;
  top: 54%;
  left: 3%;
  z-index: 0;
}

.policyField.file > input {
  opacity: 0;
  z-index: 1;
}

.policyField.file .uploadPolicy {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 0;
  width: 174px;
  height: 55px;
  border: 1px solid black;
  border-radius: 10px;
}

.uploadPolicy > span.uploadButton {
  font-size: 18px;
  font-family: "Lexend";
  font-weight: 400;
  display: flex;
  gap: 11px;
}

.uploaded-file-details {
  font-size: 15px;
  font-family: "Nunito";
  font-weight: 400;
  color: green;
}

.file-label{
  font-size: 15px;
  font-family: "Nunito";
  font-weight: 400;
  color: black;
}

.publishPolicy-btn {
  width: auto;
  height: 22px;
  background: #66a3a6;
  border: none;
  color: white;
  padding: 1.3% 2%;
  display: flex;
  align-items: center;
  gap: 2%;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Lexend";
  font-size: 18px;
}

.policy_toggle_label {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 20px;
  opacity: 70%;
}

.visibilityToggle {
  display: flex;
  gap: 20px;
}

.dropdown {
  position: absolute;
  top: 97%;
  left: 0%;
  width: 99.5%;
  background-color: white;
  z-index: 100;
  font-size: 18px;
  font-family: "Lexend";
  font-weight: 100;
  display: flex;
  flex-direction: column;
  gap: 7px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.dropDownoption {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 4px 0px 4px 2px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .createPolicyForm > form {
    padding: 1rem 2rem;
    border-radius: 8px;
  }
  .policyField input {
    padding-left: 1rem;
    height: 40px;
  }
  .publishPolicy-btn {
    width: max-content;
    height: max-content;
    padding: 0.35rem 1rem;
    font-size: 20px;
  }
  .policyField label {
    font-size: 18px;
  }
  .policyField {
    gap: 6px;
  }
  .policyField.file .uploadPolicy {
    width: 125px;
    height: 40px;
  }
}
