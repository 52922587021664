.detailsOverlay {
  position: fixed;
  top: -16px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detailsContentClass {
  background: #fff;
  width: 42rem;
  border-radius: 24px;
}
.detailsContentClass h2 {
  background-color: #66a3a6;
  margin: 0 0;
  padding: 15px 0;
  text-align: center;
  color: #fff;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 400;
}
.detailsPageTopBarContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #66a3a6;
  position: relative;
  border-radius: 24px 24px 0px 0px
}
span.closeIconClass {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.detailsData {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #969191;
  padding: 12px 0;
  flex: 0.9;
}
.detailsTitle {
  color: #66a3a6;
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  font-family: "Lexend";
  border-bottom: 1px solid;
  margin: 0;
}
.detailsCard {
  box-shadow: 3px 6px 9px #66a3a6;
  margin: 15px 30px 0;
}
.cardMarginClass{
  /* margin: 10px 0 10px; */
}
.textName {
  width: 50%;
}
.textName p {
  padding-left: 15px;
  font-family: Lexend;
  font-size: 0.96vw;
  font-weight: 400;
  margin: 0 0;
}
.textDesc {
  width: 65%;
}
.textDesc p {
  font-family: Lexend;
  font-size: 0.96vw;
  font-weight: 300;
  margin: 0 0;
}
.textDesc span {
  padding-right: 15px;
}

.detailsBtnWrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 14px 0;
}

.detailsBtnWrapper button:first-child {
  width: 7vw;
  height: 4vh;
  background-color: #fff;
  font-family: Nunito;
  font-size: 1vw;
  font-weight: 400;
  color: #ff0000;
  border-radius: 5px;
  border: 1px solid #ff0000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.detailsBtnWrapper button:nth-child(2) {
  width: 7vw;
  height: 4vh;
  background-color: #66a3a6;
  font-family: Nunito;
  font-size: 1vw;
  font-weight: 400;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.disabledButton{
  cursor: not-allowed !important;
  opacity: 0.6;
}
.capsClass{
  text-transform: capitalize;
}
/* Css for animation */
@keyframes appear {
  0%{
    opacity: 0;
    transform: translateY(-10px);
  }
}

.detailsOverlay{
  animation: appear 350ms ease-in 1;
}
/* Css for animation ends*/

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .textName p{
    font-size: 18px;
  }
  .textDesc p{
    font-size: 18px;
  }
}
@media only screen and (max-width: 450px){
  .detailsPageTopBarContainer{
    
  }
  .detailsOverlay .detailsContentClass{
    width: 90vw;
    position: absolute;
    bottom: 15%;
    border-radius: 10px;
  }
  .detailsContentClass h2{
    font-size: 18px;
  }
  span.closeIconClass img {
    width: 25px;
    height: 25px;
}

  .detailsContentClass div{
    border-bottom: none;
  }

  .detailsData p{
    font-size: 11px;
  }
  
  .detailsData .textDesc p{
    font-size: 11px;
  }
  .detailsData{
    padding: 8px 0;
  }
  
  .detailsContentClass  .detailsBtnWrapper button:first-child, .detailsContentClass .detailsBtnWrapper button:nth-child(2){
    height: 40px;
    width: 40%;
    font-size: 18px;
  }
  .detailsPageTopBarContainer {
    width: 80%;
    border-radius: 0px 0px 25px 25px;
    margin: auto;
  }
  .detailsCard{
    margin: 15px 15px 0;
  }
  .textDesc span {
    padding-right: 8px;
}


}