.filterBtnWrapper button {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background-color: #66a3a6;
  width: 160px;
  height: 45px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
}
.filterBtnWrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0 15px;
}
.screensTable {
  border-collapse: collapse;
  border-top: 1px solid #969191;
  border-bottom: 1px solid #969191;
}
.screensTable th {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
}
.screensTable tr th {
  border-right: 1px solid #969191;
  border-bottom: 1px solid #969191;
  /* padding: 1rem 9.21rem; */
  padding: 1rem 0rem;
  width: 29rem;
}
.screensTable tr th:last-child {
  border-right: none;
}
table.screensTable tbody td {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  padding: 15px 0;
  border-right: 1px solid #969191;
  border-bottom: 1px solid #969191;
}
.screensTable tbody td:last-child {
  border-right: none;
}
.addFilterOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filterTableContentWrapper {
  width: 42.938rem;
  text-align: center;
}
.addNewFilterHeading {
  background-color: #66a3a6;
  color: #fff;
  padding: 20px 0;
}
.addNewFilterHeading h2 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0;
}
.filterInputsWrapper {
  background-color: #fff;
}
.filterOverlaySaveBtn {
  width: 130px;
  height: 36px;
  background-color: #66a3a6;
  font-family: Inter;
  font-size: 19px;
  font-weight: 400;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin: 20px 5px;
  cursor: pointer;
}
button.filterOverlaySaveBtn.cancelBtn {
  background-color: #fff;
  border: 1px solid #ff0000;
  color: #ff0000;
}
.addfilterInputWrapper {
  color: #66a3a6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 10px 15px 0;
}
.addfilterInputWrapper input {
  width: 40rem;
  padding: 5px 0;
}
.filterInputsWrapper h2 {
  font-family: Inter;
  font-size: 20px;
  color: #66a3a6;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  margin: 0 15px;
}
.filterBtnClass {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.filterBtnClass button{
  font-family: Poppins;
  font-size: 12px;
  color: #fff;
  background-color: #66a3a6;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;
}
.filterBtnClass button:last-child{
  background-color: #ff0000;
}
/* Css for animation */
@keyframes appear {
  0%{
    opacity: 0;
    transform: translateY(-10px);
  }
}

.filterTableContentWrapper{
  animation: appear 350ms ease-in 1;
}
/* Css for animation ends*/

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .screensTable{
    width: 100%;
  }
  .screensTable th{
    font-size: 20px;
  }
  .screensTable tr th{
    padding: 1rem 4.21rem;
  }
}

@media only screen and (max-width: 1025px) {
  .screensTable tr th {
    padding: 1rem 1.21rem;
}
}

@media only screen and (max-width: 450px){
  .mobLoader {
    position: static !important;
}
}