/* ComingSoon.css */
.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1a1a1a;
    color: white;
    text-align: center;
  }
  
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  p {
    font-size: 1.2rem;
  }