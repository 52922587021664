/* Add this CSS to style the toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 29px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.stars-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.bookingRequestTable td span {
    cursor: pointer;
    color: #fff;
    background-color: white;
    padding: 0;
    border-radius: 17px;
    text-transform: capitalize;
}

td.rating-icons > svg {
  position: relative;
}