.formCredInputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}
.formCredWrapper {
  margin-top: 45px;
}
.formCredInputWrapper label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 5px;
}
.formCredInputWrapper input {
  width: 900px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
}
.buttonCredWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 50px 0 50px;
}

.buttonCredWrapper button:first-child {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background-color: #66a3a6;
  border: none;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}
.buttonCredWrapper button:nth-child(2) {
  font-family: Inter;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  background-color: #fff;
  border: 1px solid #000;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}
.iconDiv {
  position: absolute;
  top: 40px;
  right: 20px;
  cursor: pointer;
}
/* 
.iconDiv .visible {
} */
/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .formCredInputWrapper input {
    width: 750px;
  }
}
@media only screen and (max-width: 1025px) {
  .formCredInputWrapper input {
    width: 650px;
}
  
}