.sideBarLinksWrapper {
  width: 8.65%;
  border-right: 1px solid #b3b3b3;
  height: 100vh;
  flex: 0.3;
  transition: width 0.5s ease;
}
.sideIconsection {
  background-color: #66a3a6;
  display: flex;
  align-items: center;
  /* width: 9%; */
  padding: 38px 0px;
  justify-content: center;
}
.linksWrapper {
  margin: 60px 0 0;
  max-height: 82%;
  overflow-y: auto;
  overflow-x: hidden;

}
.sideBarLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000;
  padding: 10px;
  width: 100px;
  margin: 25px 20px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
  transition: all 0.5s;
}

.sideBarLink.selected {
  background-color: #66a3a6;
  color: #fff;
  transition: all 0.5s;
}
.linkTxtIconClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.linkIconClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.selected-icon {
  fill: #007bff;
}
a.sideBarLink.selected svg path {
  fill: #fff;
}
a.sideBarLink svg path {
  /* fill: #b3b3b3; */
}
/* css for animation */
.textAnimateClass {
  display: none;
  /* opacity: 0; */
  transition: opacity 0.3s ease;
}
.linkAnimateClass {
  width: 35px;
  /* height: 35px; */
  margin: 30px 20px;
  transition: width 0.3s ease, margin 0.3s ease;
  display: flex;
  justify-content: center;
}
.sideBarAnimateClass {
  width: 3%;
  transition: all 0.5s ease;
}
/*  */
/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */

@media only screen and (min-width: 1512px) and (max-width: 1512px) and (min-height: 892px) and (max-height: 892px) {
  .sideBarLink {
    width: auto;
  }
}

@media only screen and (max-width: 1440px) {
  .linksWrapper {
    margin: 0;
  }
  .linkTxtIconClass p {
    font-size: 12px;
  }
  .sideBarLink {
    width: 62px;
    margin: 25px 15px;
  }
}

@media only screen and (max-width: 1025px) {
  .sideBarLink {
    width: 58px;
    margin: 25px 15px;
  }
  .linkTxtIconClass p {
    font-size: 10px;
  }
  .sideBarLinksWrapper {
    flex: 0.7;
  }
}

@media only screen and (max-width: 450px) {
  .sideBarLinksWrapper {
    display: none;
  }
}
