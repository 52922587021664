@media only screen and (max-width: 450px){
  
    .contentWrapper{
      width: 100vw;
      overflow-y: auto;
    }
  
    .meetingRoomWrapper{
      margin-left: 15px;
      margin-right: 15px;
    }
  
    .meetingCollectionWrapper{
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .meetingDiv{
      width: 100%;
      height: 290px;
      background-color: white;
    }
    .meetingRoomImgClass{
      padding: 5px;
    }
  
    .meetingDetailWrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5px;
    }
  
    .meetingDetailWrapper h2{
      font-size: 15px;
      font-weight: 400px;
      margin-top: 5px;
    }
  
    .meetingDetailWrapper p{
      margin-bottom: 3px;
      font-size: 15px;
      font-weight: 300px;
      max-width: 100%;
    }

    button.addNewButton{
      width: 111px;
      height: 31px;
    }
  
    button.addNewButton a{
      font-size: 15px;
    }
    
    .buttonSection button:nth-child(1),
    .buttonSection button:nth-child(2) {
      width: 95px;
      height: 30px;
      font-size: 14px;
    }
  
    .buttonSection button img{
      width: 16px;
      height: 16px;
    }
    }


    .coachCardImg {
      object-fit: contain;
    }
/* loader css  */
