.settingsHeadingWrapper {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #66a3a6;
  margin-bottom: 25px;
  transition: background-color 0.5s ease-in-out;
}
.settingHeading {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #0000004d;
  cursor: pointer;
  border-radius: 10px 0 0 0;
  transition: background-color 0.5s ease-in-out;
}
.active {
  background-color: #66a3a6;
  color: #fff;
  transition: background-color 0.5s ease-in;
}

.flexForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filterHeading {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 10px 0 0;
}
.settingHeading h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
}
.filterHeading h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
}
.settingsWrapper {
  /* width: 95.563rem; */
  width: 86.563rem;
  border: 1px solid #0000004d;
  margin: auto;
  margin-top: 3.125rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
/*  */

/* Media Queries */
@media only screen and (min-width: 1512px) and (max-width: 1512px) and (min-height: 892px) and (max-height: 892px) {
  .settingsWrapper {
    width: 65rem;
  }

  .settingHeading h2 {
    font-size: 25px;
  }

  .filterHeading h2 {
    font-size: 25px;
  }

  .logoTxtClass {
    width: 57rem !important;
  }

  .inputTxtClass {
    width: 59rem !important;
    height: 4.938rem !important;
    margin: auto !important;
  }

  .inputTxtClass textarea {
    width: 100% !important;
  }

  .allowQuickBookingSection {
    margin: 4rem 6.25rem 3rem;
  }
}

/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1440px) {
  .settingsWrapper {
    width: 65rem;
  }
  .settingHeading h2 {
    font-size: 25px;
  }
  .filterHeading h2 {
    font-size: 25px;
  }
  .logoTxtClass {
    width: 57rem;
  }
}
@media only screen and (max-width: 1280px) {
  .settingsWrapper {
    width: 53.5rem;
  }
  .settingHeading h2 {
    font-size: 25px;
  }
  .filterHeading h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1025px) {
  .settingsWrapper {
    width: 48rem;
  }
  .settingHeading h2 {
    font-size: 20px;
  }
  .filterHeading h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 450px) {
  body {
    /* background-image: none; */
    width: 100vw;
    height: 100vh;
  }

  .contentWrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .settingsWrapper {
    width: inherit;
    height: inherit;
    border: none;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-top: 22%;
    margin-top: 0px;
  }

  .settingsHeadingWrapper {
    border-bottom: none;
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
  }

  .settingHeading,
  .filterHeading {
    width: 41%;
    height: 40px;
    border: solid 1px;
    border-radius: 5px;
  }

  .settingHeading h2,
  .filterHeading h2 {
    font-size: 18px;
  }

  .filterSubSection {
    font-size: 14px;
  }
}
