.logOutWrapper {
  position: absolute;
  top: 7.5rem;
  right: 7rem;
  width: 18.188rem;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  background-color: #fff;
}
.logoutSection a{
    text-decoration: none;
}
.settingText h2 {
    font-family: Lexend;
    font-size: 24px;
    font-weight: 300;
    margin: 0 0;
    color: #000;
    text-transform: capitalize;
}
.logoutSection {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid;
}
.manageAccntDiv {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.manageLogoutDiv{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.logoutText h2{
    font-family: Lexend;
    font-size: 24px;
    font-weight: 300;
    margin: 0 0;
    color: #000;
    text-transform: capitalize;
}
.logoutIcon {
    width: 25%;
    text-align: center;
}
.logoutText {
    width: 70%;
    text-align: center;
}
.borderHide{
   border-bottom: none;
}
/* Css for animation */
@keyframes appear {
    0%{
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  
  .logOutWrapper{
    animation: appear 350ms ease-in 1;
  }
 /* Css for animation ends*/
@media only screen and (max-width: 450px){
    .logOutWrapper{
        height: 76px;
        width: 156px;
        top: 75%;
        right: 13%;
    }
    
    .logoutSection{
        padding: 7px;
    }

    .settingText h2, .logoutText h2{
        font-size: 14px;
    }

    .settingIcon svg, .logoutIcon svg{
        width: 16px;
        height: 16px;
    }

}