.p-datatable,
.p-datatable-header,
.p-datatable-thead > tr > th,
.p-datatable-tbody > tr > td,
.p-datatable-footer {
  font-family: "Nunito", sans-serif;
}

.expanded-details {
  display: flex;
  gap: 50%;
}


.action-btns {
  width: 20%;
}

.p-datatable .p-datatable-header {
  background: #66a3a6;
}

.p-datatable-thead {
  background: #66a3a6;
}

.p-button-reject {
  background: #ff6666;
  border: none;
}

.p-button-accept {
  background: #66a3a6;
  border: none;
}

.p-filter-column {
  /* background: #66a3a6; */
}

.global-search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-search-wrapper > .pi.pi-search {
  left: 2%;
}
