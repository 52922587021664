.policyCard {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 300px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cardHeader h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .moreIcon {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .moreIcon svg {
    width: 24px;
    height: 24px;
  }
  
  .cardContent {
    margin-top: 16px;
  }
  
  .cardContent p {
    margin: 8px 0;
    font-size: 14px;
    color: #555;
  }
  
  .cardContent p strong {
    color: #333;
  }
  