.topNavbarWrapper {
  display: flex;
  width: 100%;
  height: 109px;
  border-bottom: 1px solid #000;
}

.logosection {
  display: flex;
  align-items: center;
  width: 10%;
  justify-content: center;
  border-right: 1px solid #000;
}
.logosection img {
  width: 5rem;
  height: 5rem;
  object-fit: contain
  ;
}
.headerSearchSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 54%;
  border-right: 1px solid #000;
}
.headerTxt h3 {
  font-family: Inter;
  font-size: 34px;
  font-weight: 400;
  color: #000;
  margin-left: 40px;
  text-transform: capitalize;
}
.searchBar {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 35px;
  padding: 8px;
  width: 285px;
  margin-right: 40px;
}
.searchBar input {
  flex: 0.9;
  border: none;
  outline: none;
  padding: 8px;
}
.searchBar svg {
  cursor: pointer;
}
.notificationDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6%;
  border-right: 1px solid #000;
}
.profileSection {
  display: flex;
  align-items: center;
  width: 25%;
  padding: 0 20px 0;
  color: #000;
  position: relative;
}
span.arrowIcon {
  cursor: pointer;
}

/* profile css */
.profileWrapper {
  display: flex;
  align-items: center;
}
.profileDetails {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.profileWrapper h2 {
  font-family: Lexend;
  font-size: 25px;
  font-weight: 400;
  margin: 0 35px 0 0;
}
.profImgClass {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
/* profile css */

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .headerSearchSection {
    width: 50%;
  }
  .headerTxt h3 {
    font-size: 30px;
    margin-left: 30px;
  }
  .profileSection {
    width: 30%;
  }
  .searchBar {
    margin-right: 30px;
    width: 275px;
  }
}

@media only screen and (max-width: 1025px) {
  .logosection {
    width: 12%;
  }
  .headerTxt h3 {
    font-size: 18px;
    margin-left: 15px;
  }
  .searchBar {
    margin-right: 20px;
    width: 230px;
  }
  .notificationDiv {
    width: 8%;
  }
  .profileSection {
    width: 35%;
  }
  .profileWrapper h2 {
    font-size: 18px;
  }
  .profileWrapper {
    gap: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .logosection,
  .profileWrapper h2,
  .headerTxt h3 {
    display: none;
  }

  .topNavbarWrapper,
  .headerSearchSection,
  .searchBar,
  .notificationDiv {
    border: none;
  }

  .headerSearchSection {
    flex-grow: 0.5;
  }

  .menuButtonWrapper {
    padding-left: 10px;
  }

  .searchBar {
    border: solid 1px;
    padding: 0px;
    /* width: 50%; */
    margin: 0px;
    display: flex;
    height: 36px;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
  }

  .searchBar input {
    flex: 0.9 1;
    border-radius: 20px;
    outline: none;
    padding: 8px;
  }

  .searchBar svg {
    /* min-width: 17px;
    min-height: 17px; */
    padding-right: 10px;
    position: fixed;
    right: 28%;
    width: 20px;
    height: 20px;
  }

  .topNavbarWrapper {
    width: 100%;
    height: 10%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    background-color: white;
  }

  .bellIconWrapper img {
    width: 24px;
    height: 27px;
  }

  .profileDetails {
    height: 50px;
  }

  .profileDetails img {
    height: 30px;
    width: 30px;
  }

  .profileSection {
    width: 27px;
  }

  .arrowIcon {
    display: none;
  }
}
