.filterSubHeading {
  display: flex;
  justify-content: center;
  gap: 70px;
  padding: 20px 0;
}
.filterSubSection {
  display: flex;
  border: 1px solid #000;
  align-items: center;
  width: 16rem;
  height: 3.813rem;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.filterSubSection h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
}
.active {
  background-color: #66a3a6;
  color: #fff;
  border: 1px solid #66a3a6;
}


/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .filterSubSection{
    width: 10rem;
    height: 2.5rem;
  }
  .filterSubSection h2{
    font-size: 18px;
  }
  .filterSubHeading{
    gap: 50px;
  }
}


@media only screen and (max-width: 450px){
  
  .filterSubHeading{
    gap:0px;
    display: flex;
    justify-content: space-around;
  }

  .filterSubHeading div{
    height: 30px;
    width: 25%;
  }

  .filterSubHeading div:nth-child(2){
    min-width: 30%;
  }

  .filterSubHeading div h2{
    font-size: 14px;
    font-weight: 400;
  }

  .screensTable thead{
    border: solid 2px;
  }

  .screensTable thead tr th{
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
    border: solid 2px;
  }


  .settingsWrapper .filterBtnWrapper button{
    width: 36%;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: none;
  }

  .addFilterOverlay .filterTableContentWrapper{
    width: 95vw;
  }

  .filterInputsWrapper .addfilterInputWrapper input{
    width: 100%;
  }

  .tableContainer{
    padding: 10px;
  }

  .screensTable{
    border-left:solid;
    border-right:solid;
    border-bottom: solid;
  }

  .screensTable tbody{
    border-bottom: solid;
  }
}