.fileNames {
  display: none;
}

.coachReg {
  font-size: 12px;
  text-align: center;
  font-family: Nunito;
  font-weight: 500;
}

.regCoachBtn {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  color: #66a3a6;
  text-transform: uppercase;
  background-color: #fff;
  width: 160px;
  height: 45px;
  border-radius: 5px;
  border: none;
  box-shadow: 4px 4px 4px 4px #00000040;
  cursor: pointer;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regCoachBtn.disable {
  color: #aaa;
  background-color: #f5f5f5;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.regCoachBtn.disable:hover {
  background-color: #fff;
  color: #66a3a6;
}

.regCoachBtn:hover {
  color: #fff;
  background-color: #66a3a6;
}

@media only screen and (max-width: 450px) {
  .fileNames {
    display: flex;
  }
}
