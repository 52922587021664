/* Pagination container */
.pagination {
    display: flex;
    justify-content: flex-end;
    margin-inline-end: 13.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* Pagination buttons */
  .pagination button{
    cursor: pointer;
  }
  .prevButton {
    border: 1px solid #b7b2b2;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    height: 36px;
    margin: 0 8px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    padding: 0px 10px;
  }
  .nextButton {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    background-color: #66a3a6;
    color: #fff;
    height: 36px;
    margin: 0 8px;
    border-radius: 5px;
    border: none;
    padding: 0px 15px;
  }
  
  
  /* Disabled state for pagination buttons */ 
  .prevButton[disabled] {
    background-color: #fff;
    cursor: not-allowed;
  }
  .nextButton[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 450px) {
  .pagination{
    margin-inline-end: 12.8rem;
  }
  .prevButton{
    font-size: 18px;
    height: 30px;
  }
  .nextButton{
    font-size: 18px;
    height: 30px;
  }
  @-moz-document url-prefix() {
    .pagination{
      margin-inline-end: 9.5rem;
    }
  }
 }

  