.App {
  text-align: center;
}

.creditField {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.spinner-overlay {
  position: absolute; /* Positioning the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black with some transparency */
  display: flex; /* Centering the loader */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 10; /* Ensure overlay is above the form */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .desktop-content {
    display: none;
  }

  .mobile-blocked {
    display: block;
    text-align: center;
    padding: 50px;
    font-size: 24px;
  }
}

@media only screen and (min-width: 769px) {
  .mobile-blocked {
    display: none;
  }
}
